import React, { useState, useEffect } from "react";
import { useSearchParams, Link, useNavigate } from "react-router-dom";
import {
  Button,
  Container,
  Box,
  Card,
  CardContent,
  Typography,
  CardActions,
  Backdrop,
  CircularProgress,
  Link as MuiLink,
  Stepper,
  Step,
  StepLabel,
  Divider,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Select,
  FormControl,
  Checkbox,
  MenuItem,
} from "@mui/material";
import SettingsIcon from "@mui/icons-material/Settings";
import CelebrationIcon from "@mui/icons-material/Celebration";
import FlagIcon from "@mui/icons-material/Flag";
import { QRCodeSVG } from "qrcode.react";
import axios from "axios";
import { useSnackbar } from "notistack";
import {
  browserNamesArray,
  isMobileWithTouchSupport,
  getCurrentBrowserName,
  checkBrowserSensorSupport2,
} from "../../utils/functions";
import { MapContainer, TileLayer, useMap, Polygon } from "react-leaflet";
import * as L from "leaflet";
// import * as dfns from "date-fns";

function CampaignSelect() {
  const [params, setParams] = useSearchParams();
  const [ipPosShowError, setIpPosShowError] = useState(false);
  const [ipPosition, setIpPosition] = useState(null);
  const [campaigns, setCampaigns] = useState([]); // All campaigns
  const [filteredCampaigns, setFilteredCampaigns] = useState([]); // Only browser filtered campaigns
  const [isPreview, setIsPreview] = React.useState(true);
  const [loading, setLoading] = useState(true);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const routerNavigate = useNavigate();
  const [workerId, setWorkerId] = useState(params.get("workerId"));
  const [campaignId, setCampaignId] = useState(params.get("campaignId"));
  const [slotId, setSlotId] = useState(params.get("slotId"));
  const [logId, setLogId] = useState();
  const [open, setOpen] = useState(false);
  const [selectedCampaignId, setSelectedCampaignId] = useState(null);
  const [warningMessage, setWarningMessage] = useState(
    "Your current browser does not support some of the sensors required by the campaign. Are you sure you want to proceed?"
  );
  // Initializes selected browsers based on device (mobile or desktop) and manages the selected checkboxes
  const [selectedBrowsers, setSelectedBrowsers] = useState(
    isMobileWithTouchSupport()
      ? [getCurrentBrowserName(navigator.userAgent)]
      : []
  );

  useEffect(() => {
    // console.log("SLOTID ID: " + params.get("slotId"));
    if (
      params.get("workerId") !== "DummyWorkerId" &&
      params.get("slotId") !== "DummySlotId"
    ) {
      setIsPreview(false);
      searchJob();
    } else {
      setIsPreview(true);
      sendLog();
      // setLoading(false);
    }

    getIpLocation();
  }, []);

  useEffect(() => {
    showCampaigns();
    if (selectedBrowsers.length > 0) {
      filterCampaignBasedOnBrowsers();
    } else {
      setFilteredCampaigns(campaigns); // If there is not a browser selection, filteredCampaigns = all campaigns
    }
  }, [campaigns]);

  useEffect(() => {
    getCampaigns();
  }, [ipPosition, selectedBrowsers]);

  /**
   * Backend call to get all campaigns
   */
  const getCampaigns = () => {
    if (!ipPosition) {
      return;
    }

    setLoading(true);
    axios
      .get(process.env.REACT_APP_WORKER_URL + "/campaign/getCampaigns", {
        params: {
          workerIDMW: params.get("workerId"),
          campaignIDMW: params.get("campaignId"),
          lat: ipPosition[0],
          lng: ipPosition[1],
          // lat: 0,
          // lng: 0,
          // coords: ipPosition,
        },
      })
      .then((res) => {
        setCampaigns(res.data);
      })
      .catch((err) => {
        enqueueSnackbar("Unable to get campaings", { variant: "error" });
        console.error(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  /**
   * Filter campaigns based on the browsers selected by the worker
   */
  const filterCampaignBasedOnBrowsers = () => {
    const filteredCampaigns = campaigns.filter((c) => {
      const campaignSensors = c.sensors.map((sensor) => sensor.name);
      const supportedBrowsers = checkBrowserSensorSupport2(campaignSensors);
      return selectedBrowsers.every((browser) =>
        supportedBrowsers.includes(browser)
      );
    });

    setFilteredCampaigns(filteredCampaigns);
  };

  const showCampaigns = () => {
    if (!campaigns.length) {
      return;
    }
  };

  // Manages the selection and deselection of checkboxes
  const handleChange = (event) => {
    const { value } = event.target; // Extract value selected/deselected
    setSelectedBrowsers(typeof value === "string" ? value.split(",") : value);
  };

  const searchJob = async () => {
    axios
      .get(process.env.REACT_APP_WORKER_URL + "/campaign/getWorkerActiveJob", {
        params: {
          workerId: params.get("workerId"),
          campaignIDMW: params.get("campaignId"),
        },
      })
      .then(async (res) => {
        if (res.data !== "OK") {
          goToQRPage(
            params.get("campaignId"),
            res.data.slotIDMW,
            res.data.workerIDMW
          );
        } else {
          // sendLog();
          await createJob();
        }
      })
      .catch((err) => {
        enqueueSnackbar("Error checking for active jobs", { variant: "error" });
        console.error(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const sendLog = async () => {
    axios
      .get("https://api.ipgeolocation.io/ipgeo?apiKey=" + process.env.REACT_APP_IP_GEOLOCATION_KEY, { withCredentials: false })
      .then(async (res) => {
        try {
          const res2 = await axios.post(
            process.env.REACT_APP_WORKER_URL + "/log/postCampaignListPost",
            {
              ipAddress: res.data.ip,
              ipApiResponse: JSON.stringify(res.data),
              workerId,
              campaignId,
              slotId,
            }
          );
          setLogId(res2.data.log._id);
        } catch (err) {
          enqueueSnackbar("Failed to create interaction log", {
            variant: "error",
          });

          console.error(err);
        }
      })
      .catch((err) => {
        console.error(err);
        setIpPosShowError(true);
      });
  };

  const goToQRPage = (campaingId, slotId, workerId) => {
    routerNavigate(
      `/sensorCollectQR?campaignId=${campaingId}&slotId=${slotId}&workerId=${workerId}`,
      { replace: "true" }
    );
  };

  const getIpLocation = () => {
    axios
      .get("https://api.ipgeolocation.io/ipgeo?apiKey=" + process.env.REACT_APP_IP_GEOLOCATION_KEY, { withCredentials: false })
      .then(async (res) => {
        setIpPosition([res.data.latitude, res.data.longitude]);
      })
      .catch((err) => {
        console.error(err);
        setIpPosShowError(true);
      });
  };

  const createJob = async () => {
    axios
      .post(
        process.env.REACT_APP_WORKER_URL + "/campaign/checkExistanceAndCreate",
        {
          slotId: params.get("slotId"),
          workerId: params.get("workerId"),
          campaignId: null,
          campaignIDMW: params.get("campaignId"),
          logId: logId,
        }
      )
      .then(async (res) => {
        enqueueSnackbar("Successfully started the task!", {
          variant: "success",
        });
        // goToQRPage(
        //   params.get("campaignId"),
        //   params.get("slotId"),
        //   params.get("workerId")
        // );
      })
      .catch((err) => {
        enqueueSnackbar(
          `Error while starting task (${err.response.data.message})`,
          {
            variant: "error",
          }
        );
        console.error(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  /**
   * If the current browser is not among those that support the campaign's sensors,
   * show a dialog confirming to proceed
   */
  const showWarningDialog = (campaignSelected) => {
    let browsersAvailable = checkBrowserSensorSupport2(
      campaignSelected.sensors.map((sensor) => sensor.name)
    );

    let currentBrowserOk = browsersAvailable.includes(
      getCurrentBrowserName(navigator.userAgent)
    );

    // Mobile
    if (isMobileWithTouchSupport()) {
      setWarningMessage(
        "Your current browser does not support some of the sensors required by the campaign. Are you sure you want to proceed?"
      );
      if (currentBrowserOk) {
        handleTakePart(campaignSelected.id);
      } else {
        setOpen(true);
        setSelectedCampaignId(campaignSelected.id);
      }
    }
    // Desktop
    else {
      setWarningMessage(
        "Please make sure that the browser on your phone is one of the following ones: " +
        browsersAvailable
      );
      setOpen(true);
      setSelectedCampaignId(campaignSelected.id);
    }
  };

  /**
   * Manages the initialization of a campaign on sensorworker and proceeds with its launch
   */
  const handleTakePart = async (internalCampaignId) => {
    axios
      .post(process.env.REACT_APP_WORKER_URL + "/campaign/setCampaignSW", {
        slotId: params.get("slotId"),
        workerId: params.get("workerId"),
        campaignId: internalCampaignId,
        campaignIDMW: params.get("campaignId"),
        logId: logId,
      })
      .then(async (res) => {
        enqueueSnackbar("Campaign selected!", {
          variant: "success",
        });
        goToQRPage(
          params.get("campaignId"),
          params.get("slotId"),
          params.get("workerId")
        );
      })
      .catch((err) => {
        enqueueSnackbar(
          `Error while setting campaign (${err.response.data.message})`,
          {
            variant: "error",
          }
        );
        console.error(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
      }}
    >
      {loading ? (
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={true}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      ) : (
        <></>
      )}
      <IpApiDialog open={ipPosShowError} />
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <Card
          sx={{
            m: "0.5em",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            // height: "35em",
          }}
        >
          <Typography
            variant="h4"
            sx={{ my: "0.5em", textAlign: "center", fontWeight: "bold" }}
          >
            Welcome to SensorWorker!
          </Typography>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              width: "xl",
              px: "2em",
              pb: "2em",
            }}
          >
            <Box sx={{ width: "50%", pt: "1em" }}>
              <Typography textAlign={"center"} fontSize={19}>
                Sensorworker is a{" "}
                <MuiLink
                  target="_blank"
                  href="https://en.wikipedia.org/wiki/Crowdsensing"
                >
                  crowdsensing
                </MuiLink>{" "}
                platform, where we aim to collect data from your mobile phone's
                sensors. Below is a list of Sensorworker campaigns you can
                choose from, each specific for you geographic region and with a
                list of sensors needed to complete the task for that particular
                campaign. Once the task is completed, it will be evaluated, and
                you will be rewarded in your Microworkers account as usual, but
                you can continue to participate in this Microworkers campaign to
                complete all other Sensorworker campaigns!
              </Typography>
            </Box>
            <Divider orientation="vertical" flexItem sx={{ mx: "0.5em" }} />
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                maxWidth: "50%",
                pt: "1em",
              }}
            >
              <Typography textAlign={"center"} fontSize={19} sx={{ mb: "1em" }}>
                To check which sensors are available on your mobile device, you
                can scan the following QR code. Alternatively, if you are
                already using a mobile device, you can click
                <Link to={"/sensorCheck"} target="_blank">
                  {" "}
                  here{" "}
                </Link>
              </Typography>

              <Container
                sx={{
                  p: "1em",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor: "#abbbd3",
                  width: "13em",
                  height: "13em",
                  borderRadius: "20%",
                }}
              >
                <QRCodeSVG
                  size={160}
                  bgColor="#abbbd3"
                  fgColor="black"
                  value={process.env.REACT_APP_URL + "/sensorCheck"}
                />
              </Container>
              <Typography
                variant="caption"
                sx={{ mt: "1em", textAlign: "center" }}
              >
                By taking part in any campaign, you agree to the following{" "}
                <Link to={"/disclaimer"} target="_blank">
                  Terms & Conditions
                </Link>
              </Typography>
              <Typography
                sx={{ mt: "0.5em", textAlign: "center", fontSize: 19 }}
              >
                You can contact us at
                <Typography variant="button">
                  {"  "}
                  help.sensorworker@gmail.com{"  "}
                </Typography>
                for any enquire.
              </Typography>
            </Box>
          </Box>

          <Typography
            variant="h5"
            sx={{ my: "0.5em", textAlign: "center", fontWeight: "bold" }}
          >
            Take part in a campaign below to start your task!
          </Typography>
          <Typography
            variant="h5"
            fontSize={19}
            sx={{ my: "0.5em", textAlign: "center" }}
          >
            Choose the browsers you'd like to use for campaigns, to filter and
            show you only the compatible ones
          </Typography>

          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              width: "100%",
              margin: "10px",
            }}
          >
            <FormControl sx={{ m: 1, width: "50vw" }}>
              <Select
                labelId="multiple-browsers-checkbox-label"
                id="multiple-browsers-checkbox"
                multiple
                displayEmpty
                value={selectedBrowsers}
                onChange={handleChange}
                renderValue={(selected) => {
                  if (selected.length === 0) {
                    return "Please select the browser...";
                  }
                  return selected.join(", ");
                }}
              >
                {browserNamesArray.map((browser) => (
                  <MenuItem key={browser} value={browser}>
                    <Checkbox
                      // If the browser index is greater than -1, it means that browser
                      // is present in the selectedBrowsers array and therefore the
                      // checkbox will be selected
                      checked={selectedBrowsers.indexOf(browser) > -1}
                    />
                    {browser}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
          {/* <Typography>
            {params.get("workerId") === "DummyWorkerId" ? (
              <Link
                to={`/sensorCollectQR?campaignId=${params.get(
                  "campaignId"
                )}&slotId=${params.get("slotId")}&workerId=DummyWorkerId`}
              >
                Have you already started a task?
              </Link>
            ) : (
              <></>
            )}
          </Typography> */}
        </Card>
      </Box>
      {filteredCampaigns.length > 0 ? (
        <Container>
          {filteredCampaigns.map((value, index) => {
            let bounds = null;
            console.log("trying to get bound for: ", value.title);
            if (value.campaignType === "Pos" || value.campaignType === "NoPosGeofenced") {
              bounds = L.polygon(value.geojson.coordinates.map(coord => [coord[1], coord[0]])).getBounds();
            }
            return (
              <>
                <Card key={index} sx={{ maxWidth: "lg", my: "0.3em" }}>
                  <CardContent>
                    {value.campaignType === "Pos" ? (
                      <MapContainer
                        style={{ height: "30em" }}
                        center={bounds.getCenter()}
                      >
                        <Polygon positions={value.geojson.coordinates.map(coord => [coord[1], coord[0]])} />
                        <BoundsFitting bounds={bounds} />
                        <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
                      </MapContainer>
                    ) : (<></>)
                    }
                    {value.campaignType === "WorldWide" ? (
                      <Typography sx={{ textAlign: "center" }}>
                        This campaign is available worldwide
                      </Typography>) : (<></>)
                    }
                    {value.campaignType === "NoPos" ? (
                      <Typography sx={{ textAlign: "center" }}>
                        This campaign is available worldwide and no gps data will be collected
                      </Typography>) : (<></>)
                    }
                    {value.campaignType === "NoPosGeofenced" ? (
                      <>
                        <MapContainer
                          style={{ height: "30em" }}
                          center={bounds.getCenter()}
                        >
                          <Polygon positions={value.geojson.coordinates.map(coord => [coord[1], coord[0]])} />
                          <BoundsFitting bounds={bounds} />
                          <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
                        </MapContainer>
                        <Typography sx={{ textAlign: "center" }}>
                          This campaign is available only in the selected area but no gps data will be collected from the mobile device
                        </Typography>
                      </>) : (<></>)
                    }
                    <Typography
                      sx={{
                        fontSize: "1.2em",
                        mt: "0.8em",
                      }}
                    >
                      Campaign Title: <b>{value.title}</b>
                    </Typography>

                    <Typography sx={{ mb: "0.3em" }}>
                      Campaign Description: {value.description}
                    </Typography>

                    <Typography sx={{ fontSize: "0.8em" }}>
                      <Typography
                        component={"span"}
                        sx={{ fontWeight: "bold", fontSize: "0.9em" }}
                      >
                        Sensors:{" "}
                      </Typography>
                      {value.sensors.map((sensorValue, sensorIndex) => {
                        return (
                          <span key={sensorIndex}>
                            {sensorValue.name}
                            {sensorIndex < value.sensors.length - 1 && " - "}
                          </span>
                        );
                      })}
                    </Typography>

                    <Typography sx={{ fontSize: "0.8em" }}>
                      <Typography
                        component={"span"}
                        sx={{ fontWeight: "bold", fontSize: "0.9em" }}
                      >
                        Current Browser:{" "}
                      </Typography>
                      {getCurrentBrowserName(navigator.userAgent)}
                    </Typography>

                    <Typography sx={{ fontSize: "0.8em" }}>
                      <Typography
                        component={"span"}
                        sx={{ fontWeight: "bold", fontSize: "0.9em" }}
                      >
                        The sensors of this campaign is supported by these
                        browser:{" "}
                      </Typography>
                      {checkBrowserSensorSupport2(
                        value.sensors.map((sensor) => sensor.name)
                      )}
                    </Typography>

                    <Typography sx={{ fontSize: "0.8em" }}>
                      <Typography
                        component={"span"}
                        sx={{ fontWeight: "bold", fontSize: "0.9em" }}
                      >
                        Campaign Ends:{" "}
                      </Typography>
                      {new Date(value.end).toLocaleString()}
                    </Typography>
                    {/* <Typography sx={{ fontSize: "0.8em" }}>
                    <Typography
                      component={"span"}
                      sx={{ fontWeight: "bold", fontSize: "0.9em" }}
                    >
                      Time To Complete:{" "}
                    </Typography>
                    {Object.entries(
                      dfns.intervalToDuration({
                        start: new Date(0),
                        end: new Date(value.timeToComplete),
                      })
                    ).map((val, i) => {
                      if (val[1] > 0) {
                        return (
                          <>
                            {val[1]} {val[0]}{" "}
                          </>
                        );
                      }
                    })}
                  </Typography> */}
                  </CardContent>

                  <CardActions sx={{ display: "flex" }}>
                    <Button
                      onClick={() => {
                        showWarningDialog(value);
                      }}
                      variant="contained"
                      sx={{ width: "100%" }}
                      disabled={isPreview}
                    >
                      Take Part
                    </Button>
                  </CardActions>
                </Card>
                <Dialog open={open} onClose={() => setOpen(false)}>
                  <DialogTitle>Warning</DialogTitle>
                  <DialogContent>
                    <DialogContentText>{warningMessage}</DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={() => setOpen(false)}>Cancel</Button>
                    <Button
                      onClick={() => {
                        setLoading(true);
                        handleTakePart(selectedCampaignId);
                      }}
                      variant="contained"
                    >
                      {isMobileWithTouchSupport()
                        ? "Proceed anyway"
                        : "Proceed"}
                    </Button>
                  </DialogActions>
                </Dialog>
              </>
            );
          })}
        </Container>
      ) : (
        <>
          {!loading && (
            <Box
              sx={{
                textAlign: "center",
                my: "0.5em",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Typography
                variant="h3"
                sx={{
                  color: "rgba(0, 0, 0, 0.5)",
                  fontWeight: "bold",
                  mt: "20px",
                  width: "60%",
                }}
              >
                There are no campaigns in your location, or you have too many
                browsers selected!{" "}
              </Typography>
            </Box>
          )}
        </>
      )}
    </Box>
  );
}

const IpApiDialog = ({ open }) => {
  return (
    <Dialog open={open}>
      <DialogContent>
        <Typography>
          Unable to find you position throught you network. You may consider
          turning off your adblock and reload the page.
        </Typography>
      </DialogContent>
    </Dialog>
  );
};

export const BoundsFitting = ({ bounds }) => {
  const map = useMap();
  map.fitBounds(bounds);
  return <></>;
};

const IntroStep = () => {
  return (
    <>
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <Card
          sx={{
            m: "0.5em",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            width: "100%",
            maxWidth: "lg",
            height: "25vw",
          }}
        >
          <Typography variant="h5" sx={{ py: "0.5em" }}>
            Welcome to SensorWorker!
          </Typography>
          <Typography>
            Sensorworker is{" "}
            <MuiLink
              href="https://en.wikipedia.org/wiki/Crowdsensing"
              target="_blank"
              rel="noreferrer"
            >
              Mobile Crowdsensing
            </MuiLink>{" "}
            platform completely integrated with Microworkers.
          </Typography>
          <Typography textAlign="center">
            SensorWorker aims at collecting data from the sensors in your mobile
            device straight from the browser, without requiring you to install
            any software!
            <br />
            the only requirement is that you use a browser compatible with the
            sensor readings we need.
          </Typography>
        </Card>
      </Box>
    </>
  );
};

const steps = [
  "Select campaign settings",
  "Create an ad group",
  "Create an ad",
];

export const IntroStepper = () => {
  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set());

  const isStepOptional = (step) => {
    return step === 1;
  };

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      // You probably want to guard against something like this,
      // it should never occur unless someone's actively trying to break something.
      throw new Error("You can't skip a step that isn't optional.");
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  return (
    <Card sx={{ m: "0.5em", p: "1em" }}>
      <Box sx={{ width: "100%" }}>
        <Stepper activeStep={activeStep} alternativeLabel>
          <Step key="fanuclo">
            <StepLabel
              StepIconComponent={CelebrationIcon}
              StepIconProps={{
                sx: {
                  p: "0.15em",
                  fontSize: 30,
                  backgroundColor: "#5572a1",
                  borderRadius: "100%",
                  color: "white",
                },
              }}
            >
              Welcome!
            </StepLabel>
          </Step>
          <Step key="fanuclo">
            <StepLabel
              StepIconComponent={SettingsIcon}
              StepIconProps={{
                sx: {
                  p: "0.15em",
                  fontSize: 30,
                  backgroundColor: "#5572a1",
                  borderRadius: "100%",
                  color: "white",
                },
              }}
            >
              How it works
            </StepLabel>
          </Step>
          <Step key="fanuclo">
            <StepLabel
              StepIconComponent={FlagIcon}
              StepIconProps={{
                sx: {
                  p: "0.15em",
                  fontSize: 30,
                  backgroundColor: "#5572a1",
                  borderRadius: "100%",
                  color: "white",
                },
              }}
            >
              Next steps
            </StepLabel>
          </Step>
        </Stepper>
        <div>
          {activeStep === steps.length ? (
            <React.Fragment>
              <Typography sx={{ mt: 2, mb: 1 }}>
                All steps completed - you&apos;re finished
              </Typography>
              <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                <Box sx={{ flex: "1 1 auto" }} />
                <Button onClick={handleReset}>Reset</Button>
              </Box>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <Typography sx={{ mt: 2, mb: 1 }}>
                Step {activeStep + 1}
              </Typography>
              <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                <Button
                  color="inherit"
                  disabled={activeStep === 0}
                  onClick={handleBack}
                  sx={{ mr: 1 }}
                >
                  Back
                </Button>
                <Box sx={{ flex: "1 1 auto" }} />
                {isStepOptional(activeStep) && (
                  <Button color="inherit" onClick={handleSkip} sx={{ mr: 1 }}>
                    Skip
                  </Button>
                )}

                <Button onClick={handleNext}>
                  {activeStep === steps.length - 1 ? "Finish" : "Next"}
                </Button>
              </Box>
            </React.Fragment>
          )}
        </div>
      </Box>
    </Card>
  );
};

export default CampaignSelect;
