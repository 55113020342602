import React, { useEffect, useState } from "react";
import * as dfns from "date-fns";
import { useNavigate } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import InfoIcon from "@mui/icons-material/Info";
import AddIcon from "@mui/icons-material/Add";
import CentralCard from "../components/CentralCard";
import {
  Box,
  Typography,
  CardContent,
  Button,
  TextField,
  Tooltip,
  Select,
  MenuItem,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
} from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { useSnackbar } from "notistack";
import {
  MapContainer,
  TileLayer,
  CircleMarker,
  useMapEvents,
  Polygon,
} from "react-leaflet";
import axios from "axios";
import { useOutletContext } from "react-router-dom";
import { getFloatFixedValue } from "../utils/functions";
import { Container } from "@mui/system";

function CreateCampaign() {
  const routerNavigate = useNavigate();

  const [name, setName] = useState("");
  const [campaignType, setCampaignType] = useState("Pos");
  const [description, setDescription] = useState("");
  const [startDate, setStartDate] = useState(
    /* dfns.add(new Date(), { days: 1 }) */ new Date()
  );
  const [endDate, setEndDate] = useState(dfns.add(new Date(), { days: 2 }));
  const [polygon, setPolygon] = useState([]);
  const [buffer, setBuffer] = useState(100);
  const [TTCseconds, setTTCseconds] = useState(0);
  const [TTCminutes, setTTCminutes] = useState(0);
  const [TTChours, setTTChours] = useState(0);
  const [newPositions, setNewPositions] = useState(1);

  const [question, setQuestion] = useState("");

  const [openAddSensorDialog, setOpenAddSensorDialog] = useState(false);
  const [sensorTypes, setSensorTypes] = useState([
    "Accelerometer",
    "Gyroscope",
    "Magnetometer",
    "AmbientLightSensor",
    "GravitySensor",
    "LinearAccelerationSensor",
    "RelativeOrientationSensor",
    "AbsoluteOrientationSensor",
    "Photo",
    "Audio",
    "Video",
  ]);
  const [sensorList, setSensorList] = useState([]);
  const [campaignInfo, setCampaignInfo] = useOutletContext();
  const [price, setPrice] = useState();

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  useEffect(() => {
    if (campaignInfo) {
      setPrice(
        getFloatFixedValue(newPositions * campaignInfo.pricePerPosition) /*  *
          (1 + 10 / 100) */
          .toFixed(2)
      );
    }
  }, [newPositions, campaignInfo]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (newPositions === 0) {
      enqueueSnackbar("The number of positions must be greater than 0!", {
        variant: "error",
      });
      return;
    }
    if (campaignType === "Pos" && polygon.length < 3) {
      enqueueSnackbar(
        "Geolocation bounds polygon must have at least 3 points!",
        {
          variant: "error",
        }
      );
      return;
    }

    const tmpTTC =
      dfns.hoursToSeconds(TTChours) +
      dfns.minutesToSeconds(TTCminutes) +
      TTCseconds;

    if (tmpTTC === 0) {
      enqueueSnackbar("Invalid field: Time to Complete!", { variant: "error" });
      return;
    }

    if (tmpTTC >= dfns.differenceInSeconds(endDate, startDate)) {
      enqueueSnackbar("Time to complete exceeds campaign lenght!", {
        variant: "error",
      });
      return;
    }
    if (sensorList.length === 0) {
      enqueueSnackbar("No sensors have been added!", { variant: "error" });
      return;
    }

    if (campaignType === "" || campaignType === null) {
      enqueueSnackbar("Invalid field: Campaign type!", { variant: "error" });
      return;
    }

    try {
      const transformedPolygon = polygon.map(coord => [coord[1], coord[0]]); // Swap lat, lng → lng, lat

      if (campaignType === "Pos" || campaignType === "NoPosGeofenced") {
        await axios.post(
          process.env.REACT_APP_BASE_URL + "/crowdsourcer/createCampaign",
          {
            campaignType: campaignType,
            geojson: { type: "MultiPoint", coordinates: transformedPolygon },
            sensors: sensorList,
            timeToComplete: dfns.add(new Date(0), { seconds: tmpTTC }),
            totalJobs: newPositions,
            begin: startDate,
            end: endDate,
            buffer: buffer,
            title: name,
            description: description,
            ...(question ? { question } : {}),
          }
        );
      } else if (campaignType === "NoPos" || campaignType === "WorldWide") {
        await axios.post(
          process.env.REACT_APP_BASE_URL + "/crowdsourcer/createCampaign",
          {
            campaignType: campaignType,
            sensors: sensorList,
            timeToComplete: dfns.add(new Date(0), { seconds: tmpTTC }),
            totalJobs: newPositions,
            begin: startDate,
            end: endDate,
            title: name,
            description: description,
            ...(question ? { question } : {}),
          }
        );
      }
      enqueueSnackbar("Campaign created!", { variant: "success" });
      routerNavigate("/crowdsourcer");
    } catch (err) {
      console.error(err);
      enqueueSnackbar("Failed to create campaign", { variant: "error" });
    }
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <SensorAddDialog
        open={openAddSensorDialog}
        onClose={() => {
          setOpenAddSensorDialog(false);
        }}
        types={sensorTypes}
        setTypes={setSensorTypes}
        sensorList={sensorList}
        setSensorList={setSensorList}
      />
      <CentralCard maxWidth="100%">
        <Typography variant="h3" sx={{ textAlign: "center" }}>
          Create a Campaign
        </Typography>
        <Typography>Fill up these field to create a Campaign</Typography>
        <form onSubmit={handleSubmit} style={{ minWidth: "100%" }}>
          <CardContent>
            {/* campaign title */}
            <Box sx={{ textAlign: "center", mb: "1em" }}>
              <Typography sx={{ display: "flex", justifyContent: "center" }}>
                Campaign title
                <Tooltip title="Title of the internal campaign" placement="top">
                  <InfoIcon sx={{ mx: "0.2em", fontSize: 20, color: "grey" }} />
                </Tooltip>
              </Typography>
              <TextField
                required={true}
                size="small"
                value={name}
                onChange={(ev) => {
                  setName(ev.target.value);
                }}
              />
            </Box>

            {/* campaign Description */}
            <Box sx={{ textAlign: "center", mb: "1em" }}>
              <Typography sx={{ display: "flex", justifyContent: "center" }}>
                Campaign description
                <Tooltip
                  title="Description of the internal campaign"
                  placement="top"
                >
                  <InfoIcon sx={{ mx: "0.2em", fontSize: 20, color: "grey" }} />
                </Tooltip>
              </Typography>
              <TextField
                required={true}
                multiline
                rows={4}
                size="small"
                value={description}
                onChange={(ev) => {
                  setDescription(ev.target.value);
                }}
              />
            </Box>

            {/* campaign start and end */}
            <Box sx={{ textAlign: "center", mb: "1em" }}>
              <Typography sx={{ display: "flex", justifyContent: "center" }}>
                Start - end
                <Tooltip
                  title="Time frame in which workers can join the campaign"
                  placement="top"
                >
                  <InfoIcon sx={{ mx: "0.2em", fontSize: 20, color: "grey" }} />
                </Tooltip>
              </Typography>
              <DatePicker
                selectsRange={true}
                minDate={new Date()}
                startDate={startDate}
                endDate={endDate}
                onChange={(update) => {
                  setStartDate(update[0]);
                  setEndDate(update[1]);
                }}
                withPortal
              />
            </Box>

            {/* campaign type */}
            <Box sx={{ textAlign: "center", mb: "1em" }}>
              <Typography sx={{ display: "flex", justifyContent: "center" }}>
                Campaign type
                <Tooltip
                  title="Type of the campaign: position required, no position required(so no GPS will be required to the user and no geofence to join the campaign), worldwide(user's position will be registered but users can join the campaign from everywhere), no position required but geofenced(no GPS will be required to the user but the user must be inside the geofence to join the campaign)"
                  placement="top"
                >
                  <InfoIcon sx={{ mx: "0.2em", fontSize: 20, color: "grey" }} />
                </Tooltip>
              </Typography>
              <Select
                size="small"
                value={campaignType}
                onChange={(e) => {
                  setCampaignType(e.target.value);
                }}
                defaultValue="Pos"
              >
                <MenuItem value="Pos">Position required</MenuItem>
                <MenuItem value="NoPos">No position required</MenuItem>
                <MenuItem value="NoPosGeofenced">No position required but geofenced</MenuItem>
                <MenuItem value="WorldWide">WorldWide</MenuItem>
              </Select>
            </Box>

            {/* campaign geo */}
            {campaignType === "Pos" || campaignType === "NoPosGeofenced" ? (
              <Box sx={{ textAlign: "center" }}>
                <Typography sx={{ display: "flex", justifyContent: "center" }}>
                  Geolocation bounds
                  <Tooltip
                    title="The worker must be inside the polygon to perform the job"
                    placement="top"
                  >
                    <InfoIcon sx={{ mx: "0.2em", fontSize: 20, color: "grey" }} />
                  </Tooltip>
                </Typography>
                <Button
                  variant="contained"
                  sx={{ mb: "0.8em" }}
                  onClick={() => {
                    setPolygon([]);
                  }}
                >
                  Clear bounds
                </Button>
                <Map polygon={polygon} setPolygon={setPolygon} />
              </Box>
            ) : null}

            {/* campaign buffer */}
            {campaignType === "Pos" || campaignType === "NoPosGeofenced" ? (
              <Box sx={{ textAlign: "center", mb: "1em" }}>
                <Typography sx={{ display: "flex", justifyContent: "center" }}>
                  Buffer
                  <Tooltip
                    title="The workers within the polygon + this buffer will be allowed in the campaign"
                    placement="top"
                  >
                    <InfoIcon sx={{ mx: "0.2em", fontSize: 20, color: "grey" }} />
                  </Tooltip>
                </Typography>
                <Select
                  size="small"
                  value={buffer}
                  onChange={(e) => {
                    setBuffer(Number(e.target.value));
                  }}
                >
                  <MenuItem value={100}>100 km</MenuItem>
                  <MenuItem value={200}>200 km</MenuItem>
                  <MenuItem value={300}>300 km</MenuItem>
                  <MenuItem value={400}>400 km</MenuItem>
                  <MenuItem value={500}>500 km</MenuItem>
                  <MenuItem value={600}>600 km</MenuItem>
                  <MenuItem value={700}>700 km</MenuItem>
                  <MenuItem value={800}>800 km</MenuItem>
                  <MenuItem value={900}>900 km</MenuItem>
                  <MenuItem value={1000}>1000 km</MenuItem>
                </Select>
              </Box>
            ) : null}
            {/* campaign time to complete */}
            <Box sx={{ textAlign: "center", mb: "1em" }}>
              <Typography sx={{ display: "flex", justifyContent: "center" }}>
                Time to Complete
                <Tooltip
                  title="How much time is available to the worker to complete the job"
                  placement="top"
                >
                  <InfoIcon sx={{ mx: "0.2em", fontSize: 20, color: "grey" }} />
                </Tooltip>
              </Typography>
              <Container>
                <TextField
                  label="Hours"
                  sx={{ m: "0.3em" }}
                  required={true}
                  size="small"
                  value={TTChours}
                  inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
                  onChange={(e) => {
                    const val = Number(e.target.value);

                    setTTChours(val);
                  }}
                />
                <TextField
                  label="Minutes"
                  sx={{ m: "0.3em" }}
                  required={true}
                  size="small"
                  value={TTCminutes}
                  inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
                  onChange={(e) => {
                    const val = Number(e.target.value);
                    if (val <= 60) {
                      setTTCminutes(val);
                    }
                  }}
                />
                <TextField
                  label="Seconds"
                  sx={{ m: "0.3em" }}
                  required={true}
                  size="small"
                  value={TTCseconds}
                  inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
                  onChange={(e) => {
                    const val = Number(e.target.value);
                    if (val <= 60) {
                      setTTCseconds(val);
                    }
                  }}
                />
              </Container>
            </Box>

            {/* campaign sensors */}
            <Box sx={{ textAlign: "center", mb: "1em" }}>
              <Typography sx={{ display: "flex", justifyContent: "center" }}>
                Sensors
                <Tooltip
                  title="List of sensors that the worker must send data from"
                  placement="top"
                >
                  <InfoIcon sx={{ mx: "0.2em", fontSize: 20, color: "grey" }} />
                </Tooltip>
              </Typography>
              <Box sx={{ textAlign: "center" }}>
                {sensorList.map((s, i) => {
                  return (
                    <Box
                      key={i}
                      sx={{
                        bgcolor: "#C5CFDF",
                        boxShadow: 1,
                        borderRadius: 2,
                        py: "0.5em",
                        px: "0.5em",
                        my: "0.5em",
                      }}
                    >
                      <Typography
                        variant="h5"
                        sx={{ mb: "0.3em", textAlign: "center" }}
                      >
                        {s.name.match(/[A-Z][a-z]+|[0-9]+/g).join(" ")}
                      </Typography>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-around",
                        }}
                      >
                        <Typography>
                          <b>Readings</b>: {s.maxReadings}
                        </Typography>
                        <Typography>
                          <b>Time Interval</b>: {s.timeInterval}s
                        </Typography>
                        <Typography>
                          <b>Space Interval</b>: {s.spaceInterval}m
                        </Typography>
                      </Box>
                      <Box>
                        {s.instruction ? (
                          <Typography>
                            <b>Instructions</b>: {s.instruction}
                          </Typography>
                        ) : (
                          <></>
                        )}
                      </Box>
                      <Button
                        size="small"
                        variant="contained"
                        color="error"
                        sx={{ width: "100%", mt: "0.5em" }}
                        onClick={() => {
                          let newSensorList = sensorList;
                          newSensorList = newSensorList.filter((el) => {
                            return el.name !== s.name;
                          });
                          setSensorList(newSensorList);
                          let newTypes = sensorTypes;
                          newTypes.push(s.name);
                          setSensorTypes(newTypes);
                        }}
                      >
                        remove
                      </Button>
                    </Box>
                  );
                })}

                <Button
                  variant="contained"
                  sx={{ borderRadius: 50, mt: "0.5em" }}
                  onClick={() => {
                    // console.log(sensorList);
                    setOpenAddSensorDialog(true);
                  }}
                >
                  <AddIcon sx={{ fontSize: 30 }} />
                </Button>
              </Box>
            </Box>

            {/* campaign question */}
            <Box sx={{ textAlign: "center", mb: "1em" }}>
              <Typography sx={{ display: "flex", justifyContent: "center" }}>
                Question
                <Tooltip
                  title="Question answerable by the worker one time. Optional"
                  placement="top"
                >
                  <InfoIcon sx={{ mx: "0.2em", fontSize: 20, color: "grey" }} />
                </Tooltip>
              </Typography>
              <TextField
                required={false}
                multiline
                rows={4}
                size="small"
                value={question}
                onChange={(ev) => {
                  setQuestion(ev.target.value);
                }}
              />
            </Box>

            {/* campaign new positions */}
            <Box sx={{ textAlign: "center", mb: "1em" }}>
              <Typography sx={{ display: "flex", justifyContent: "center" }}>
                Number of positions
                <Tooltip
                  title={
                    campaignInfo
                      ? `How many position related to this internal campaign to add in the Microworkers campaign, the cost per position is ${campaignInfo.pricePerPosition} + 10%`
                      : ""
                  }
                  placement="top"
                >
                  <InfoIcon sx={{ mx: "0.2em", fontSize: 20, color: "grey" }} />
                </Tooltip>
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <TextField
                  size="small"
                  value={newPositions}
                  inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
                  onChange={(e) => {
                    const val = Number(e.target.value);

                    if (isNaN(val)) {
                      setNewPositions(1);
                    } else {
                      setNewPositions(val);
                    }

                    // if (val > 0) {
                    //   setNewPositions(val);
                    //   console.log(val);
                    // }else if (val === 0){
                    //   setNewPositions(0);
                    // }
                  }}
                />
                <Typography sx={{ ml: "1em" }}>Cost: {price}</Typography>
              </Box>
            </Box>
            {campaignInfo && (
              <Button
                type="submit"
                size="large"
                variant="contained"
                sx={{ width: "100%" }}
              >
                add campaign
              </Button>
            )}
          </CardContent>
        </form>
      </CentralCard>
    </LocalizationProvider>
  );
}

const Map = ({ polygon, setPolygon }) => {
  return (
    <Box sx={{ height: "30em" }}>
      <MapContainer
        style={{ height: "90%" }}
        center={[51.505, -0.09]}
        zoom={9}
        scrollWheelZoom={true}
      >
        {polygon.map((coords, i) => {
          return (
            <CircleMarker
              color="red"
              key={i}
              center={coords}
              radius={3}
              fillOpacity={100}
            />
          );
        })}
        <Polygon
          pathOptions={{ weight: 3, color: "red" }}
          positions={polygon}
        />
        <ClickHandler polygon={polygon} setPolygon={setPolygon} />
        <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
      </MapContainer>
    </Box>
  );
};

function ClickHandler({ polygon, setPolygon }) {
  const map = useMapEvents({
    click(e) {
      console.log(e.latlng);
      setPolygon([...polygon, [e.latlng.lat, e.latlng.lng]]);
    },
  });

  return null;
}

const SensorAddDialog = ({
  open,
  onClose,
  types,
  setTypes,
  sensorList,
  setSensorList,
}) => {
  const [chosenType, setChosenType] = useState(types[0]);
  const [readings, setReadings] = useState(1);
  const [timeInterval, setTimeInterval] = useState(1);
  const [spaceInterval, setSpaceInterval] = useState(1);
  const [instruction, setInstruction] = useState("");

  const handleAdd = () => {
    const sensor = {
      name: chosenType,
      maxReadings: readings,
      timeInterval: timeInterval,
      spaceInterval: spaceInterval,
      instruction: instruction === "" ? null : instruction,
    };
    let tmp = types;

    setSensorList([...sensorList, sensor]);

    tmp = tmp.filter((el) => el !== chosenType);
    setTypes(tmp);

    setChosenType(tmp[0]);
    setReadings(1);
    setTimeInterval(1);
    setSpaceInterval(1);
    setInstruction("");
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth={"md"}>
      <DialogTitle>Add Sensor</DialogTitle>
      <DialogContent>
        <Box>
          <Typography>Sensor type</Typography>
          <Select
            fullWidth
            value={chosenType}
            onChange={(e) => {
              setChosenType(e.target.value);
              setInstruction("");
            }}
          >
            {types.map((type, index) => {
              return (
                <MenuItem value={type} key={index}>
                  {type.match(/[A-Z][a-z]+|[0-9]+/g).join(" ")}
                </MenuItem>
              );
            })}
          </Select>
        </Box>

        <Box sx={{ my: "1em" }}>
          <Typography>Number of readings</Typography>
          <TextField
            type="number"
            value={readings}
            onChange={(e) => {
              const val = Number(e.target.value);
              if (val > 0) {
                setReadings(val);
              }
            }}
          />
        </Box>

        <Box sx={{ my: "1em" }}>
          <Typography>Time interval (seconds)</Typography>
          <TextField
            type="number"
            value={timeInterval}
            onChange={(e) => {
              const val = Number(e.target.value);
              if (val >= 0 && val < 3540) {
                setTimeInterval(val);
              }
            }}
          />
        </Box>

        <Box sx={{ my: "1em" }}>
          <Typography>Space interval (meters)</Typography>
          <TextField
            type="number"
            value={spaceInterval}
            onChange={(e) => {
              const val = Number(e.target.value);
              if (val >= 0) {
                setSpaceInterval(val);
              }
            }}
          />
        </Box>

        {chosenType === "Photo" ||
          chosenType === "Video" ||
          chosenType === "Audio" ? (
          <Box sx={{ my: "1em" }}>
            <Typography>Instructions</Typography>
            <TextField
              multiline
              rows={4}
              size="small"
              value={instruction}
              onChange={(e) => {
                const val = e.target.value;
                // if (val >= 0) {
                //   setSpaceInterval(val);
                // }
                setInstruction(val);
              }}
            />
          </Box>
        ) : (
          <></>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          disabled={types.length === 0 || !chosenType}
          onClick={handleAdd}
          variant="contained"
          sx={{ width: "100%" }}
        >
          add
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CreateCampaign;
